<template>
  <!-- split sidebar and rest -->
  <div class="flex">
    <!-- sidebar -->
    <div :class="`w-72 h-screen ${surfaceColour} drop-shadow-xl`">
      <sidebar />
    </div>

    <!-- content -->
    <div :class="`flex flex-col w-full h-screen ${backgroundColor}`">
      <navigationBar class="shadow-lg shadow-slate-100" />
      <slot></slot>
    </div>
  </div>
</template>

<script>
import navigationBar from '@/components/navigationBar.vue'
import useTheme from '@/use/themeHandler'
import sidebar from './sidebar/sideBar.vue'

export default {
  setup() {
    const themeHandler = useTheme()
    const surfaceColour = themeHandler.getBackgroundColor('surface')
    const backgroundColor = themeHandler.getBackgroundColor('background')
    return { backgroundColor, surfaceColour }
  },
  components: {
    navigationBar,
    sidebar,
  },
}
</script>
