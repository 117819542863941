export default {
  install: (app) => {
    app.config.globalProperties.$filter = {
      dateFromEpochDay(value) {
        if (value) {
          const date = new Date(value)
          const dateFormatted = `${`0${date.getDate()}`.slice(-2)}-${`0${date.getMonth() + 1}`.slice(
            -2
          )}-${date.getFullYear()}`
          return dateFormatted
        }
        return 'No date'
      },
    }
  },
}
