<template>
  <div class="flex flex-row">
    <!-- icon -->
    <featherIcon class="w-4 h-4 my-auto mr-2 text-gray-500" icon="ClockIcon" />

    <!-- All filters aligned -->
    <div class="flex flex-auto items-center">
      <!-- Container for all dropdowns -->

      <!-- Range Selector -->
      <div class="flex-initial">
        <!-- Controls width of the dropdown trigger -->
        <base-button-drop-down
          class="mr-2"
          :handler="rangeDropDown"
          :display-text="useTime.intervalDisplayText.value"
          variant="neutral"
        >
          <base-button-drop-down-item
            v-for="(displayText, key) in useTime.filteredDisplayTextSelector.value"
            :key="key"
            @click="toggleRange(key)"
          >
            {{ $translate(displayText) }}
          </base-button-drop-down-item>
        </base-button-drop-down>
      </div>

      <!-- Day Filter -->
      <div class="flex-initial">
        <!-- Controls width of the dropdown trigger -->
        <base-button-drop-down
          class="mr-2"
          v-if="useTime.range.value === 'day'"
          :handler="dayDropDown"
          :display-text="String(useTime.selectedDay.value)"
          variant="neutral"
          :translate="false"
        >
          <div class="grid grid-cols-7 text-xs text-gray-300">
            <div v-for="day in weekdays" :key="day" class="text-center p-1">{{ day }}</div>
          </div>
          <div class="grid grid-cols-7 gap-1 bg-white w-max p-2">
            <!-- offset cells for day alignment -->
            <div v-for="n in dayOffset()" :key="`empty-${n}`" class="text-center rounded"></div>

            <!-- Adjusts gap between grid cells -->
            <base-button-drop-down-item
              v-for="day in useTime.days.value"
              :key="day.number"
              class="text-center rounded !p-2"
              :class="{
                'font-bold': isWeekend(day.date),
                'border rounded': isToday(day.date),
                'bg-sky-700 text-white': isSelected(day.date),
              }"
              @click="toggleDay(day.number)"
            >
              {{ day.number }}
            </base-button-drop-down-item>
          </div>
        </base-button-drop-down>
      </div>

      <!-- Month Filter -->
      <div class="flex-initial">
        <!-- Controls width of the dropdown trigger -->
        <base-button-drop-down
          class="mr-2"
          v-if="useTime.range.value === 'day' || useTime.range.value === 'month'"
          :handler="monthDropDown"
          :display-text="monthNames[useTime.selectedMonth.value]"
          variant="neutral"
        >
          <base-button-drop-down-item
            v-for="month in useTime.months.value"
            :key="month.number"
            @click="toggleMonth(month.number - 1)"
          >
            {{ $translate(month.name) }}
          </base-button-drop-down-item>
        </base-button-drop-down>
      </div>

      <!-- Year Filter -->
      <div class="flex-initial">
        <!-- Controls width of the dropdown trigger -->
        <base-button-drop-down
          :handler="yearDropDown"
          :display-text="String(useTime.selectedYear.value)"
          variant="neutral"
          :translate="false"
        >
          <base-button-drop-down-item v-for="year in useTime.years.value" :key="year" @click="toggleYear(year)">
            {{ year }}
          </base-button-drop-down-item>
        </base-button-drop-down>
      </div>
    </div>
  </div>
</template>

<script>
import baseButtonDropDownHandler from '@/use/baseButtonDropDownHandler'
import timeHandler from './timeHandler'
import { ref } from 'vue'

export default {
  setup() {
    const rangeDropDown = baseButtonDropDownHandler()
    const yearDropDown = baseButtonDropDownHandler()
    const monthDropDown = baseButtonDropDownHandler()
    const dayDropDown = baseButtonDropDownHandler()
    const useTime = timeHandler()

    function toggleRange(interval) {
      useTime.setInterval(interval)
      rangeDropDown.toggle()
    }

    function toggleYear(year) {
      useTime.updateSelectedYear(year)
      yearDropDown.toggle()
    }

    function toggleMonth(monthIndex) {
      useTime.updateSelectedMonth(monthIndex)
      monthDropDown.toggle()
    }

    function toggleDay(day) {
      useTime.updateSelectedDay(day)
      dayDropDown.toggle()
    }

    function isWeekend(date) {
      const dayOfWeek = date.getDay()
      return dayOfWeek === 0 || dayOfWeek === 6
    }

    const today = ref(new Date())

    function isToday(dayDate) {
      const now = new Date(today.value)
      return (
        dayDate.getDate() === now.getDate() &&
        dayDate.getMonth() === now.getMonth() &&
        dayDate.getFullYear() === now.getFullYear()
      )
    }

    function isSelected(dayDate) {
      return (
        useTime.selectedDay.value &&
        dayDate.getDate() === useTime.selectedDay.value &&
        dayDate.getMonth() === useTime.selectedMonth.value &&
        dayDate.getFullYear() === useTime.selectedYear.value
      )
    }

    function dayOffset() {
      const start = new Date(useTime.selectedYear.value, useTime.selectedMonth.value, 1)
      return (start.getDay() + 6) % 7 // +6 makes Sunday (0) index 6
    }

    const monthNames = [
      'January',
      'February',
      'March',
      'April',
      'May',
      'June',
      'July',
      'August',
      'September',
      'October',
      'November',
      'December',
    ]

    const weekdays = ['m', 'd', 'w', 'd', 'v', 'z', 'z']

    return {
      toggleRange,
      toggleYear,
      toggleMonth,
      toggleDay,
      rangeDropDown,
      useTime,
      yearDropDown,
      monthDropDown,
      dayDropDown,
      monthNames,
      isWeekend,
      dayOffset,
      weekdays,
      isToday,
      isSelected,
    }
  },
}
</script>
