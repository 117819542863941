import { ref, computed } from 'vue'
import formHandler from '@/use/formHandler'
import {
  eachDayOfInterval,
  endOfMonth,
  endOfYear,
  getYear,
  startOfMonth,
  startOfYear,
  startOfDay,
  endOfDay,
} from 'date-fns'

const selectedYear = ref(getYear(new Date()))
const selectedMonth = ref(new Date().getMonth())
const selectedDay = ref(new Date().getDate())

const startDate = ref(startOfMonth(new Date(selectedYear.value, selectedMonth.value)))
const endDate = ref(endOfMonth(new Date(selectedYear.value, selectedMonth.value)))
const range = ref('month')

const selectForm = formHandler()

export default () => {
  const years = computed(() => {
    const currentYear = getYear(new Date())
    return Array.from({ length: 5 }, (_, i) => currentYear - i)
  })

  const months = computed(() => {
    if (range.value === 'month' || range.value === 'day') {
      const currentYear = getYear(new Date())
      const monthNames = [
        'January',
        'February',
        'March',
        'April',
        'May',
        'June',
        'July',
        'August',
        'September',
        'October',
        'November',
        'December',
      ]
      return monthNames.map((name, index) => {
        const date = new Date(currentYear, index)
        return { number: index + 1, name: name, date }
      })
    }
    return []
  })

  const days = computed(() => {
    if (range.value === 'day') {
      const selectedDate = new Date(selectedYear.value, selectedMonth.value)
      const start = startOfMonth(selectedDate)
      const end = endOfMonth(selectedDate)
      return eachDayOfInterval({ start, end }).map((date) => ({
        number: date.getDate(),
        date: date,
      }))
    }
    return []
  })

  function setInterval(payload) {
    range.value = payload
    const today = new Date()

    if (payload === 'year') {
      // Reset selectedMonth to current month when switching to 'year'
      selectedMonth.value = today.getMonth()
    }

    if (payload !== 'day') {
      // Reset selectedDay to today's date if not in 'day' range
      selectedDay.value = today.getDate()
    }

    switch (payload) {
      case 'year':
        startDate.value = startOfYear(new Date(selectedYear.value, 0))
        endDate.value = endOfYear(new Date(selectedYear.value, 0))
        break

      case 'month':
        startDate.value = startOfMonth(new Date(selectedYear.value, selectedMonth.value))
        endDate.value = endOfMonth(new Date(selectedYear.value, selectedMonth.value))
        break

      case 'day':
        startDate.value = startOfDay(new Date(selectedYear.value, selectedMonth.value, selectedDay.value))
        endDate.value = endOfDay(new Date(selectedYear.value, selectedMonth.value, selectedDay.value))
        break
    }
  }

  const interval = computed(() => {
    const mapper = {
      year: 'month',
      month: 'day',
      day: 'hour',
    }
    return mapper[range.value]
  })

  const displayTextSelector = {
    day: 'Day',
    month: 'Month',
    year: 'Year',
  }

  const intervalDisplayText = computed(() => displayTextSelector[range.value])

  const filteredDisplayTextSelector = computed(() => {
    return Object.fromEntries(Object.entries(displayTextSelector).filter(([key]) => key !== range.value))
  })

  // This function updates the selected year and adjusts start/end dates accordingly
  function updateSelectedYear(year) {
    selectedYear.value = year
    if (range.value === 'year') {
      startDate.value = startOfYear(new Date(year, 0))
      endDate.value = endOfYear(new Date(year, 0))
    } else if (range.value === 'month') {
      startDate.value = startOfMonth(new Date(year, selectedMonth.value))
      endDate.value = endOfMonth(new Date(year, selectedMonth.value))
    } else if (range.value === 'day') {
      startDate.value = startOfDay(new Date(year, selectedMonth.value, selectedDay.value))
      endDate.value = endOfDay(new Date(year, selectedMonth.value, selectedDay.value))
    }
  }

  // This function updates the selected month and adjusts start/end dates accordingly
  function updateSelectedMonth(monthIndex) {
    selectedMonth.value = monthIndex
    if (range.value === 'month') {
      startDate.value = startOfMonth(new Date(selectedYear.value, monthIndex))
      endDate.value = endOfMonth(new Date(selectedYear.value, monthIndex))
    } else if (range.value === 'day') {
      startDate.value = startOfDay(new Date(selectedYear.value, monthIndex, selectedDay.value))
      endDate.value = endOfDay(new Date(selectedYear.value, monthIndex, selectedDay.value))
    }
  }

  // This function updates the selected day and adjusts start/end dates accordingly
  function updateSelectedDay(day) {
    selectedDay.value = day
    if (range.value === 'day') {
      const date = new Date(selectedYear.value, selectedMonth.value, day)
      startDate.value = startOfDay(date)
      endDate.value = endOfDay(date)
    }
  }

  // parses ISO date format to dutch human readable
  function labelSelector(interval, dt) {
    const locale = 'nl-NL' // Dutch locale

    let options
    switch (interval) {
      case 'year':
        options = { year: 'numeric' }
        break
      case 'month':
        options = { month: 'short', year: 'numeric' }
        break
      case 'day':
        options = { day: '2-digit', month: '2-digit', year: 'numeric' }
        break
      case 'hour':
        options = { hour: '2-digit', minute: '2-digit' }
        break
      default:
        throw new Error('Invalid interval provided')
    }

    return new Intl.DateTimeFormat(locale, options).format(new Date(dt))
  }

  return {
    startDate,
    endDate,
    interval,
    years,
    months,
    days,
    setInterval,
    displayTextSelector,
    intervalDisplayText,
    filteredDisplayTextSelector,
    range,
    selectForm,
    selectedYear,
    selectedMonth,
    selectedDay,
    updateSelectedYear,
    updateSelectedMonth,
    updateSelectedDay,
    labelSelector,
  }
}
