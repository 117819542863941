<template>
  <div>
    <div class="relative">
      <!-- button -->
      <base-button :variant="variant" :size="size" :loading="loading" :disabled="disabled" @action="handler.toggle()">
        <div class="flex flex-row">
          <span v-if="!icon" class="my-auto text-xs">
            <span v-if="translate">{{ $translate(displayText) }}</span>
            <span v-else>{{ displayText }}</span>
          </span>
          <span v-else><featherIcon class="w-4" :icon="icon" /></span>
          <span v-if="!icon" class="mt-auto ml-2"><featherIcon class="w-4" icon="ChevronDownIcon" /></span>
        </div>
      </base-button>
      <!-- value list -->
      <div
        :id="uniqueId"
        v-if="handler.open.value"
        :class="{ 'right-aligned': alignRight, 'left-aligned': !alignRight }"
        class="rounded shadow-md bg-white absolute mt-2 z-20 py-1"
      >
        <slot></slot>
      </div>
    </div>
  </div>
</template>

<script>
import { onMounted, onUnmounted } from 'vue'
import { v4 as uuidv4 } from 'uuid'

export default {
  props: {
    variant: {
      type: String,
      default: 'primary',
    },
    size: {
      type: String,
      default: 'normal',
    },
    loading: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    translate: {
      type: Boolean,
      default: true,
    },
    displayText: {
      type: String,
      default: 'normal',
    },
    icon: {
      type: String,
      default: '',
    },
    handler: {
      type: Object,
      default: () => {},
    },
    alignRight: {
      type: Boolean,
      default: false,
    },
  },
  setup(props) {
    const uniqueId = `dropdown_${uuidv4()}`

    onMounted(() => window.addEventListener('click', clicker))
    onUnmounted(() => window.removeEventListener('click', clicker))

    function clicker(event) {
      if (props.handler.open.value) {
        const dropdownElement = document.getElementById(uniqueId)
        if (dropdownElement && !dropdownElement.contains(event.target)) {
          props.handler.toggle(event)
        } else {
          console.log('Click inside dropdown or element not found:', uniqueId)
        }
      }
    }

    return {
      uniqueId,
    }
  },
}
</script>

<style>
.right-aligned {
  right: 0;
}
.left-aligned {
  left: 0;
}
</style>
