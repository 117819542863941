<template>
  <div class="h-full min-h-0 overflow-auto p-4">
    <base-page-title
      class="mb-8 mt-4"
      :title="pageTitle"
      :suffix="useOrganisationDetails.data.value.name"
      :icon="meta.titleIcon"
    ></base-page-title>

    <div class="grid grid-cols-12 gap-4">
      <base-card class="col-span-6 mr-4">
        <base-button
          class="mb-2"
          @action="useOrganisationDetails.edit()"
          :loading="useOrganisationDetails.loadHandler.states.edit_node"
          ><featherIcon class="w-5 h-5" icon="SaveIcon"
        /></base-button>
        <baseForm :handler="useOrganisationDetails.editForm" />
      </base-card>
    </div>

    <base-page-title class="my-8" :title="'Gekoppelde nodes'" :icon="'CircleIcon'"></base-page-title>

    <!-- Nodes -->
    <baseTable
      :fields="useOrganisationDetails.nodeFields.value"
      :records="useOrganisationDetails.currentNodes.value"
      :records-per-page="15"
      :add-button="true"
      :edit-button="false"
      :delete-button="true"
      :clickAble="false"
      :loading="useOrganisationDetails.loadHandler.somethingLoading.value"
      :selectable="false"
      :hover-effect="true"
      keyField="object_id"
      @add="useOrganisationDetails.openAdd"
      @delete="useOrganisationDetails.unassignNode"
      leadIcon="CircleIcon"
    />

    <base-page-title class="my-8" :title="'Gekoppelde users'" :icon="'UserIcon'"></base-page-title>

    <!-- Users -->
    <baseTable
      :fields="useOrganisationDetails.userFields.value"
      :records="useOrganisationDetails.users.value"
      :records-per-page="15"
      :add-button="true"
      :edit-button="false"
      :delete-button="true"
      :clickAble="false"
      :loading="useOrganisationDetails.loadHandler.somethingLoading.value"
      :selectable="false"
      :hover-effect="true"
      keyField="user_id"
      @add="useOrganisationDetails.openAssignUser()"
      @delete="useOrganisationDetails.unassigUser"
      leadIcon="UsersIcon"
    />

    <base-page-title class="my-8" :title="'Node types'" :icon="'CircleIcon'"></base-page-title>

    <!-- Node types -->
    <baseTable
      :fields="useOrganisationDetails.nodeTypeFields.value"
      :records="useOrganisationDetails.nodeTypes.value"
      :records-per-page="15"
      :add-button="true"
      :edit-button="true"
      :delete-button="true"
      :clickAble="false"
      :loading="useOrganisationDetails.loadHandler.somethingLoading.value"
      :selectable="false"
      :hover-effect="true"
      keyField="object_id"
      @add="useOrganisationDetails.openAddNodeType()"
      @delete="useOrganisationDetails.deleteNodeType"
      @edit="useOrganisationDetails.openEditNodeType"
      leadIcon="UsersIcon"
    />

    <!-- logs -->
    <base-page-title class="my-8" :title="'Logs'" :icon="'TerminalIcon'"></base-page-title>

    <baseTable
      :fields="useOrganisationDetails.logFields.value"
      :records="useOrganisationDetails.logs.value"
      :records-per-page="15"
      :add-button="false"
      :edit-button="false"
      :delete-button="false"
      :clickAble="false"
      :loading="useOrganisationDetails.loadHandler.somethingLoading.value"
      :selectable="false"
      :hover-effect="true"
      keyField="id"
      leadIcon="TerminalIcon"
      :search="false"
    />

    <!-- add node types modal -->
    <baseModal
      v-if="useOrganisationDetails.showAddNodeType.value"
      @close="useOrganisationDetails.closeAddNodeType()"
      :title="$translate('Add')"
      size="s"
    >
      <baseForm
        :handler="useOrganisationDetails.addNodeTypeForm"
        :loading="useOrganisationDetails.loadHandler.states['add_node_type']"
      />
      <!-- add button -->
      <template v-slot:buttons>
        <baseButton
          @action="useOrganisationDetails.addNodeType()"
          :loading="useOrganisationDetails.loadHandler.states[`add_node_type`]"
        >
          {{ $translate('Add') }}
        </baseButton>
      </template>
    </baseModal>

    <!-- edit node types modal -->
    <baseModal
      v-if="useOrganisationDetails.showEditNodeType.value"
      @close="useOrganisationDetails.closeEditNodeType()"
      :title="$translate('Edit')"
      size="s"
    >
      <baseForm
        :handler="useOrganisationDetails.editNodeTypeForm"
        :loading="useOrganisationDetails.loadHandler.states['edit_node_type']"
      />
      <!-- edit button -->
      <template v-slot:buttons>
        <baseButton
          @action="useOrganisationDetails.editNodeType()"
          :loading="useOrganisationDetails.loadHandler.states[`edit_node_type`]"
        >
          {{ $translate('Edit') }}
        </baseButton>
      </template>
    </baseModal>

    <!-- Users add modal -->
    <baseModal
      v-if="useOrganisationDetails.showAssignUser.value"
      @close="useOrganisationDetails.closeAssignUser"
      :title="$translate('Add')"
      size="xl"
    >
      <baseTable
        :fields="useOrganisationDetails.userFields.value"
        :records="useOrganisationDetails.usersAvailable.value"
        :records-per-page="15"
        :clickAble="true"
        :loading="useOrganisationDetails.loadHandler.states['list_users_available']"
        :selectable="true"
        :hover-effect="true"
        keyField="user_id"
        @selection="useOrganisationDetails.syncSelectionUsers"
        leadIcon="CircleIcon"
      />

      <template v-slot:buttons>
        <baseButton
          @action="useOrganisationDetails.assignUsers()"
          :loading="useOrganisationDetails.loadHandler.states[`assign_users`]"
        >
          {{ $translate('Add') }}
        </baseButton>
      </template>
    </baseModal>

    <!-- Nodes add modal -->
    <baseModal
      v-if="useOrganisationDetails.showAdd.value"
      @close="useOrganisationDetails.closeAdd"
      :title="$translate('Add')"
      size="xl"
    >
      <baseTable
        :fields="useOrganisationDetails.nodesAvailablefields.value"
        :records="useOrganisationDetails.availableNodes.value"
        :records-per-page="15"
        :clickAble="true"
        :loading="useOrganisationDetails.loadHandler.states['list_nodes_available']"
        :selectable="true"
        :hover-effect="true"
        keyField="object_id"
        @add="useOrganisationDetails.openAdd"
        @selection="useOrganisationDetails.syncSelection"
        leadIcon="CircleIcon"
      />

      <template v-slot:buttons>
        <baseButton
          @action="useOrganisationDetails.assignNodes()"
          :loading="useOrganisationDetails.loadHandler.states[`assign_nodes`]"
        >
          {{ $translate('Add') }}
        </baseButton>
      </template>
    </baseModal>
  </div>
</template>

<script>
import { useRoute } from 'vue-router'
import { onMounted, computed } from 'vue'
import organisationDetailsHandler from '@/use/viewHandlerOrganisationDetails'

export default {
  setup() {
    const route = useRoute()
    const useOrganisationDetails = organisationDetailsHandler()
    const objectId = route.params.objectId
    const meta = computed(() => route.meta)

    const pageTitle = computed(() => {
      return meta.value.title
    })

    const pageSuffix = ''

    onMounted(() => {
      useOrganisationDetails.getDetails(objectId)
    })

    return {
      useOrganisationDetails,
      objectId,
      pageTitle,
      pageSuffix,
      meta,
    }
  },
}
</script>
