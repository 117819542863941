import axios from 'axios'

const baseUrl = 'https://geodata.nationaalgeoregister.nl/locatieserver/v3'

export default () => {
  function suggest(payload) {
    const params = {
      params: {
        q: payload,
      },
    }
    return axios.get(`${baseUrl}/suggest`, params)
  }

  function lookup(payload) {
    const params = {
      params: {
        id: payload,
      },
    }
    return axios.get(`${baseUrl}/lookup`, params)
  }

  return {
    suggest,
    lookup,
  }
}
