<template>
  <div class="h-full min-h-0 overflow-auto p-4">
    <div class="h-full" ref="tableContainer">
      <baseTable
        :fields="useNodes.fields.value"
        :records="useNodes.database_records.value"
        :records-per-page="15"
        :add-button="true"
        :edit-button="false"
        :delete-button="true"
        :clickAble="true"
        :clickCallback="goToDetails"
        :loading="useNodes.loadHandler.somethingLoading.value"
        :selectable="false"
        :hover-effect="true"
        :containerHeight="containerHeight"
        keyField="object_id"
        @add="useNodes.openAdd"
        @delete="useNodes.deleteNodes"
        leadIcon="CircleIcon"
        :enableMiddleClick="true"
        :middleClickCallback="goToDetailsnewTab"
      />
    </div>

    <!-- add modal -->
    <baseModal v-if="useNodes.showAdd.value" @close="useNodes.closeAdd" :title="$translate('Add')" size="xl">
      <baseTable
        :fields="useNodes.fieldsProvider.value"
        :records="useNodes.filteredAvailableNodes.value"
        :records-per-page="15"
        :clickAble="true"
        :loading="useNodes.loadHandler.states['list_nodes_available']"
        :selectable="true"
        :hover-effect="true"
        :containerHeight="containerHeight"
        keyField="provider_identifier"
        @add="useNodes.openAdd"
        @selection="useNodes.syncSelection"
        leadIcon="CircleIcon"
      />

      <!-- add button -->
      <template v-slot:buttons>
        <baseButton @action="useNodes.addNodes()" :loading="useNodes.loadHandler.states[`add_nodes`]">
          {{ $translate('Add') }}
        </baseButton>
      </template>
    </baseModal>
  </div>
</template>

<script>
import nodesHandler from '@/use/viewHandlerNodes'
import useTabs from '@/use/tabHandler'
import { ref, computed, onMounted } from 'vue'
import { useRouter } from 'vue-router'

export default {
  setup() {
    const tableContainer = ref(null)
    const useNodes = nodesHandler()
    const tabs = useTabs()
    const router = useRouter()

    // tabs
    const tabOptions = {
      tabs: ['Ealyze', 'B.One Middleware', 'IZAR Plus portal'],
    }

    onMounted(() => {
      tabs.create(tabOptions)
      useNodes.getNodes()
    })

    const containerHeight = computed(() => {
      if (tableContainer.value) {
        return tableContainer.value.clientHeight
      }
      return 0
    })

    function goToDetails(record) {
      const objectId = record.object_id
      router.push({ name: 'node-view', params: { objectId: objectId } })
    }

    function goToDetailsnewTab(record) {
      const objectId = record.object_id
      const route = router.resolve({ name: 'node-view', params: { objectId: objectId } })
      window.open(route.href, '_blank')
    }

    return {
      useNodes,
      tableContainer,
      containerHeight,
      tabs,
      goToDetails,
      goToDetailsnewTab,
    }
  },
}
</script>
