import { createAuth0 } from '@auth0/auth0-vue'

const domain = process.env.VUE_APP_AUTH0_DOMAIN
const client_id = process.env.VUE_APP_AUTH0_CLIENT_ID
const redirect_uri = process.env.VUE_APP_AUTH0_REDIRECT_URI
const audience = process.env.VUE_APP_AUTH0_AUDIENCE

const auth = createAuth0({
  domain: domain,
  client_id: client_id,
  redirect_uri: redirect_uri,
  audience: audience,
})

export default auth
