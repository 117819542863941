import { ref } from 'vue'

export default () => {
  const open = ref(false)

  function toggle(event) {
    if (event) {
      event.preventDefault()
    }
    open.value = !open.value
  }

  return {
    toggle,
    open,
  }
}
