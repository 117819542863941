import { ref } from 'vue'
import apiHandler from '@/use/apiHandler'
import loadingHandler from '@/use/loadingHandler'
import selectValues from '@/use/selects/values'
import formHandler from '@/use/formHandler'

export default () => {
  // data
  let tasks = ref([])

  // handlers
  const useApi = apiHandler()
  const loadHandler = loadingHandler()
  const currentTask = ref(null)
  const showAddTaskModal = ref(false)
  const addForm = formHandler()
  const isPolling = ref(false)

  const taskFields = ref([
    {
      label: 'Id',
      key: 'id',
      type: 'id',
      table: false,
      add: false,
      edit: false,
    },
    {
      label: 'Uitvoerdatum',
      key: 'date_created',
      type: 'date',
      add: false,
      edit: false,
      filter: 'dateTimeFromEpoch',
    },
    // {
    //   label: 'Name',
    //   key: 'name',
    //   type: 'string',
    //   add: false,
    //   edit: false,
    // },
    {
      label: 'Type',
      key: 'pipeline_id',
      type: 'select',
      options: selectValues.pipelines,
      default: selectValues.pipelines[0].value,
    },
    {
      label: 'Status',
      key: 'status',
      type: 'string',
      add: false,
      edit: false,
      component: 'pillTaskStatus',
    },
  ])

  // list
  function getTasks(relatable_id = null, relatable_type = null) {
    loadHandler.setLoadingState('get_tasks', true)
    isPolling.value = true

    let url = 'v1/tasks/'
    if (relatable_id || relatable_type) {
      url += '?'
      if (relatable_id) url += `relatable_id=${relatable_id}&`
      if (relatable_type) url += `relatable_type=${relatable_type}`
    }

    function fetchTasks() {
      return useApi
        .requestV2('get', url)
        .then((response) => {
          tasks.value = response.data
          const hasIncompleteTasks = tasks.value.some((task) => task.status === 'running' || task.status === 'created')

          if (hasIncompleteTasks && isPolling.value) {
            // If there are still running tasks, schedule next poll after 5 seconds
            setTimeout(fetchTasks, 5000)
          } else {
            // No more running tasks or polling was stopped
            loadHandler.setLoadingState('get_tasks', false)
            isPolling.value = false
          }
        })
        .catch((error) => {
          console.error('Error fetching tasks:', error)
          loadHandler.setLoadingState('get_tasks', false)
          isPolling.value = false
        })
    }

    // Start the initial fetch
    return fetchTasks()
  }

  function stopPolling() {
    isPolling.value = false
  }

  // get task details
  function getTaskDetails(taskId) {
    loadHandler.setLoadingState('get_task_details', true)
    return useApi
      .requestV2('get', `v1/tasks/${taskId}`)
      .then((response) => {
        currentTask.value = response.data
        loadHandler.setLoadingState('get_task_details', false)
      })
      .catch(() => {
        loadHandler.setLoadingState('get_task_details', false)
      })
  }

  // create task
  function createTask(nodeId, provider_identifier) {
    loadHandler.setLoadingState('create_task', true)
    const data = addForm.data.value

    data['relatable_id'] = nodeId
    data['relatable_type'] = 'node'
    data['input'] = {
      node_ids: [provider_identifier],
    }

    return useApi
      .requestV2('post', 'v1/tasks/', data)
      .then((response) => {
        getTasks(nodeId, 'node')
        loadHandler.setLoadingState('create_task', false)
        return response.data
      })
      .catch((error) => {
        loadHandler.setLoadingState('create_task', false)
        throw error
      })
      .finally(() => {
        closeAddTaskModal()
      })
  }

  // delete task
  function deleteTask(taskId, nodeId) {
    loadHandler.setLoadingState('delete_task', true)
    return useApi
      .requestV2('delete', `v1/tasks/${taskId}`)
      .then(() => {
        getTasks(nodeId, 'node')
        loadHandler.setLoadingState('delete_task', false)
      })
      .catch(() => {
        loadHandler.setLoadingState('delete_task', false)
      })
  }

  function openAddTaskModal() {
    addForm.create({
      type: 'add',
      fields: taskFields.value,
      data: {},
    })
    showAddTaskModal.value = true
  }

  function closeAddTaskModal() {
    showAddTaskModal.value = false
  }

  return {
    tasks,
    getTasks,
    getTaskDetails,
    createTask,
    taskFields,
    loadHandler,
    currentTask,
    showAddTaskModal,
    openAddTaskModal,
    closeAddTaskModal,
    addForm,
    deleteTask,
    stopPolling,
  }
}
