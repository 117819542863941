import { ref, watchEffect } from 'vue'
import pdokHandler from '@/use/pdokHandler'

export default () => {
  // data
  let searchInput = ref('')
  let searchResult = ref([])
  let lookedUp = ref({})

  const { suggest, lookup } = pdokHandler()

  // wat cher
  watchEffect(() => {
    onInput()
  })

  function onInput() {
    if (searchInput.value.length > 0) {
      suggest(searchInput.value).then((response) => {
        searchResult.value = response.data.response.docs
      })
    }
  }

  function onLookup(payload) {
    lookup(payload).then((response) => {
      lookedUp.value = response.data.response.docs
    })
  }

  return {
    searchInput,
    searchResult,
    onLookup,
  }
}
