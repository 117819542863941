<template>
  <button
    @click.stop="action()"
    type="button"
    :class="`${sizing} ${shadow} ${backgroundColor} ${textColour} ${loadStyle} ${hoverStyle} rounded`"
  >
    <!-- loading spinner -->
    <span v-if="loading">
      <svg
        :class="spinnerSizing"
        class="animate-spin"
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
      >
        <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
        <path
          class="opacity-75"
          fill="currentColor"
          d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
        ></path>
      </svg>
    </span>

    <span v-else>
      <slot></slot>
    </span>
  </button>
</template>

<script>
import { computed } from 'vue'
import themeHandler from '@/use/themeHandler'

export default {
  props: {
    variant: {
      type: String,
      default: 'primary',
    },
    size: {
      type: String,
      default: 'normal',
    },
    loading: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['action'],
  setup(props, { emit }) {
    const useTheme = themeHandler()

    const backgroundColor = computed(() => {
      if (!props.disabled) {
        return useTheme.getBackgroundColor(props.variant)
      }
      return useTheme.getBackgroundColor('disabled')
    })

    const textColour = computed(() => {
      if (!props.disabled) {
        return useTheme.getTextColor(props.variant)
      }
      return useTheme.getTextColor('disabled')
    })

    const loadStyle = computed(() => {
      if (props.loading || props.disabled) {
        return 'cursor-not-allowed'
      }
      return ''
    })

    const hoverStyle = computed(() => {
      if (!props.disabled) {
        return useTheme.getHover(props.variant)
      }
      return useTheme.getHover('disabled')
    })

    const shadow = computed(() => {
      if (props.variant === 'transparent') {
        return ''
      }
      return 'shadow-md'
    })

    // sizing
    const sizeSelector = {
      small: 'px-2 py-1.5 text-xs',
      normal: 'px-4 py-3 text-sm',
      large: '',
    }

    const spinnerSizeSelector = {
      small: 'h-4 w-4',
      normal: 'h-5 w-5',
      large: 'h-5 w-5',
    }

    function action() {
      if (!props.loading && !props.disabled) {
        emit('action')
      }
    }

    const sizing = computed(() => sizeSelector[props.size])
    const spinnerSizing = computed(() => spinnerSizeSelector[props.size])

    return {
      backgroundColor,
      textColour,
      sizing,
      loadStyle,
      action,
      shadow,
      hoverStyle,
      spinnerSizeSelector,
      spinnerSizing,
    }
  },
}
</script>
