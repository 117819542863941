<template>
  <span>
    <!-- top bar -->
    <div class="flex flex-row justify-between" ref="headerRef">
      <!-- add button -->
      <baseButton class="px-4 my-2" v-if="addButton" @action="onAdd()" :disabled="loading">
        <featherIcon class="w-5 h-5" icon="PlusIcon" />
      </baseButton>
      <!-- search -->
      <div v-if="search" class="relative px-4 py-3 bg-white shadow-md text-sm text-gray-500 my-2">
        <div class="flex absolute inset-y-0 left-0 items-center pl-3 pointer-events-none">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            class="h-5 w-5"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
            stroke-width="2"
          >
            <path stroke-linecap="round" stroke-linejoin="round" d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z" />
          </svg>
        </div>

        <input v-model="searchQuery" class="pl-8 focus:outline-none w-40" :placeholder="$translate('Search')" />
      </div>

      <div class="my-2" v-else></div>
    </div>

    <!-- table -->
    <div class="relative overflow-x-auto shadow-md sm:rounded-lg">
      <table class="w-full text-sm text-left text-gray-500">
        <thead class="text-xs text-gray-700 bg-gray-100" ref="titleRef">
          <tr>
            <!-- icon cell -->
            <td class="px-6 py-3" v-if="leadIcon"></td>

            <!-- normal header cells -->
            <th
              scope="col"
              class="px-6 py-3 cursor-pointer"
              v-for="(field, index) in filteredFields"
              :key="index"
              @click="onSort(field, index)"
            >
              <div v-if="sortable" class="flex">
                <span class="my-auto">{{ $translate(field.label) }}</span>
                <chevronupdown v-if="index === sortFieldIndex" class="h-5 w-5 min-w-5 min-h-5 ml-2 my-auto" />
                <chevronupdown v-else class="h-5 w-5 ml-2 my-auto text-gray-400" />
              </div>
              <div v-else>
                {{ $translate(field.label) }}
              </div>
            </th>

            <!-- action header cell -->
            <th v-if="showActions" class="px-6 py-3 w-60">Acties</th>
          </tr>
        </thead>
        <tbody>
          <!-- row looper -->
          <tr
            @click="handleClick(record)"
            @auxclick="handleMiddleClick(record, $event)"
            :class="rowStyle + ' ' + isSelected(record)"
            class="bg-white border-b"
            v-for="(record, index) in filteredRecordsPaginated"
            :key="index"
          >
            <!-- icon cell -->
            <td class="px-6 py-4" v-if="leadIcon">
              <featherIcon class="w-4 h-4" :icon="leadIcon" />
            </td>

            <!-- normal cells -->
            <td class="px-6 py-4" v-for="(field, index) in filteredFields" :key="index">
              <component v-if="field.component" :is="field.component" :value="record[field.key]" />
              <span v-else-if="field.filter">
                {{ useFilter.filter(record[field.key], field.filter) }}
              </span>
              <span v-else-if="field.type === 'select'">
                {{ getSelectLabel(field, record[field.key]) }}
              </span>
              <span v-else>
                <span v-if="field.component === 'pillMainType'">
                  <div>
                    <pillMainType :variant="record[field.key]" />
                  </div>
                </span>
                <span v-else>
                  <span v-if="field.translate_value">{{ $translate(record[field.key]) }}</span>
                  <span v-else>{{ record[field.key] }}</span>
                </span>
              </span>
            </td>

            <!-- action cells -->
            <td v-if="showActions" class="px-6 py-4 w-60">
              <span v-if="editButton">
                <baseButton size="small" @click="onEdit(record)" class="mr-2 my-0" :disabled="loading">
                  <PencilIcon class="h-4 w-4" />
                </baseButton>
              </span>
              <span v-if="deleteButton">
                <baseButton
                  size="small"
                  @click="onDelete(record[keyField])"
                  class="mr-2 my-0"
                  variant="danger"
                  :disabled="loading"
                >
                  <TrashIcon class="h-4 w-4" />
                </baseButton>
              </span>

              <!-- action slot -->
              <span>
                <slot name="actions" :record="record"></slot>
              </span>
            </td>
          </tr>

          <slot name="footer"></slot>
        </tbody>
      </table>
      <!-- no data box -->
      <span v-if="showNoRecords && !loading" class="flex justify-center bg-white">
        <span class="py-6 text-center text-xs text-gray-700">Geen gegevens gevonden</span>
      </span>

      <!-- loader -->
      <span v-if="loading" class="flex justify-center bg-white">
        <span class="py-6 text-center text-xs text-gray-700">
          <baseLoader :loading="loading" />
        </span>
      </span>
    </div>

    <!-- pagination -->
    <div v-if="pagination" class="flex flex-row-reverse" ref="footerRef">
      <div
        :class="onLastPage ? 'bg-gray-100 cursor-not-allowed' : 'bg-white'"
        class="px-4 py-3 bg-white shadow-md text-sm text-gray-500 my-2 cursor-pointer select-none"
        @click="movePpage(1)"
      >
        {{ '>' }}
      </div>
      <div class="px-4 py-3 bg-white shadow-md text-sm text-gray-500 my-2 mx-2">{{ pageIndex + 1 }}/{{ lastPage }}</div>
      <div
        v-if="!onFirstPage"
        class="px-4 py-3 bg-white shadow-md text-sm text-gray-500 cursor-pointer my-2 select-none"
        @click="movePpage(-1)"
      >
        {{ '<' }}
      </div>
    </div>
  </span>
  <!-- confirmer -->
  <confimation-modal :handler="confirm" />
</template>

<script>
import { computed, ref } from 'vue'
import { TrashIcon, PencilIcon } from '@heroicons/vue/outline'
import confirmationHandler from '@/use/confirmationHandler'
import confimationModal from '@/components/extended/confirmationModal.vue'
import featherIcon from '@/components/extended/feather/featherIcon.vue'
import filterHandler from '@/use/filterHandler'
import pillMainType from '@/views/components/pillMainType.vue'
import chevronupdown from '@/components/extended/chevronUpDown.vue'

export default {
  props: {
    fields: {
      type: Array,
      default: () => [],
    },
    records: {
      type: Array,
      default: () => [],
    },
    leadIcon: {
      type: String,
      default: '',
    },
    pagination: {
      type: Boolean,
      default: true,
    },
    search: {
      type: Boolean,
      default: true,
    },
    searchType: {
      type: String,
      default: 'all',
    },
    recordsPerPage: {
      type: Number,
      default: 10,
    },
    addButton: {
      type: Boolean,
      default: false,
    },
    editButton: {
      type: Boolean,
      default: false,
    },
    deleteButton: {
      type: Boolean,
      default: false,
    },
    keyField: {
      type: String,
      default: 'object_id',
    },
    clickAble: {
      type: Boolean,
      default: false,
    },
    clickCallback: {
      type: Function,
      default: () => {},
    },
    loading: {
      type: Boolean,
      default: false,
    },
    selectable: {
      type: Boolean,
      default: false,
    },
    containerHeight: {
      type: Number,
      default: null,
    },
    hoverEffect: {
      type: Boolean,
      default: false,
    },
    sortable: {
      type: Boolean,
      default: false,
    },
    enableMiddleClick: {
      type: Boolean,
      default: false,
    },
    middleClickCallback: {
      type: Function,
      default: null,
    },
  },
  emits: ['add', 'edit', 'delete', 'selection'],
  setup(props, { emit }) {
    const pageIndex = ref(0)
    const selected = ref({})
    const searchQuery = ref('')

    const headerRef = ref(null)
    const footerRef = ref(null)
    const titleRef = ref(null)

    // handlers
    const confirm = confirmationHandler()
    const useFilter = filterHandler()

    // sorting
    let sortFieldIndex = ref(null)
    let sortField = ref({})
    let sortAsc = ref(true)

    // fields filter
    const filteredFields = computed(() => {
      return props.fields.filter((field) => {
        if (field.table === false) {
          return false
        }
        return true
      })
    })

    // filter
    const filteredRecords = computed(() => {
      // check if records need filtering
      if (searchQuery.value) {
        const searchQueryLower = searchQuery.value.toLowerCase()
        const queryResult = filterByValue(props.records, searchQueryLower)
        return queryResult
      } else {
        return props.records
      }
    })

    // sort
    const sortedRecords = computed(() => {
      // check if records need filtering
      if (sortFieldIndex.value || sortFieldIndex.value === 0) {
        const key = sortField.value.key
        const type = sortField.value.type
        const copy = JSON.parse(JSON.stringify(filteredRecords.value))
        return sort(copy, key, type)
      } else {
        return JSON.parse(JSON.stringify(filteredRecords.value))
      }
    })

    // pagination
    const filteredRecordsPaginated = computed(() => {
      if (props.pagination) {
        return paginate(sortedRecords.value)
      } else {
        return sortedRecords.value
      }
    })

    // checks if on first page
    const onFirstPage = computed(() => {
      return pageIndex.value === 0
    })

    // checks if on last page
    const onLastPage = computed(() => {
      return filteredRecords.value.length / props.recordsPerPage < pageIndex.value + 1
    })

    // checks what last page's index is
    const lastPage = computed(() => {
      return Math.ceil(filteredRecords.value.length / props.recordsPerPage)
    })

    // mutates pageindex, used by up and down buttons
    function movePpage(direction) {
      if (direction === -1 && !onFirstPage.value) {
        pageIndex.value += direction
      } else if (direction === 1 && !onLastPage.value) {
        pageIndex.value += direction
      }
    }

    // search filter
    function filterByValue(array, string) {
      return array.filter((record) =>
        props.fields.some((field) => {
          if (field.searchable) {
            // get value via keystring
            const value = parseKeypath(field.key, record)

            if (value) {
              return value.toLowerCase().includes(string)
            } else {
              return false
            }
          }
        })
      )
    }

    // paginaton filter
    function paginate(source) {
      const startIndex = pageIndex.value * props.recordsPerPage
      const endIndex = startIndex + props.recordsPerPage
      return source.slice(startIndex, endIndex)
    }

    // sort
    function sort(source, key, type) {
      let returnValue = []
      if (type === 'string') {
        returnValue = source.sort((a, b) => {
          if (!a[key]) return -1
          if (!b[key]) return 1
          return a[key].toLowerCase() > b[key].toLowerCase() ? 1 : -1
        })

        // returnValue = source.sort((a, b) => (a[key].toLowerCase() > b[key].toLowerCase() ? 1 : -1))
      } else {
        returnValue = source.sort((a, b) => (a[key] > b[key] ? 1 : -1))
      }

      if (!sortAsc.value) {
        return returnValue.reverse()
      } else {
        return returnValue
      }
    }

    // show actions column
    const showActions = computed(() => {
      return props.editButton || props.deleteButton
    })

    // show no records message
    const showNoRecords = computed(() => {
      if (props.records.length > 0) {
        return false
      }
      return true
    })

    // add button emitter
    function onAdd() {
      if (props.addButton) {
        emit('add')
      }
    }

    // edit button emitter
    function onEdit(id) {
      if (props.editButton) {
        emit('edit', id)
      }
    }

    // delete button emitter
    async function onDelete(id) {
      if (props.deleteButton) {
        const ok = await confirm.open({
          message: 'deleteObject',
        })
        if (ok) {
          emit('delete', id)
        }
      }
    }

    // clickable
    const rowStyle = computed(() => {
      let style = ''
      if (props.clickAble) {
        style += 'cursor-pointer'
      }

      if (props.hoverEffect) {
        style += ' hover:bg-gray-100'
      }

      return style
    })

    // selected
    function isSelected(record) {
      const identifier = record[props.keyField]
      if (identifier in selected.value) {
        return 'bg-blue-100'
      } else {
        return ''
      }
    }

    // Handle left click
    function handleClick(record) {
      const identifier = record[props.keyField]

      if (props.selectable) {
        if (identifier in selected.value) {
          delete selected.value[identifier]
        } else {
          selected.value[identifier] = record
        }
        // emit selected values so external components can hook into it
        emit('selection', selected)
      } else if (props.clickAble) {
        props.clickCallback(record)
      }
    }

    // Handle middle click
    function handleMiddleClick(record, event) {
      if (event.button === 1 && props.middleClickCallback) {
        event.preventDefault()
        props.middleClickCallback(record)
      }
    }

    function parseKeypath(keypath, target) {
      return keypath.split('.').reduce((previous, current) => previous[current], target)
    }

    // available height
    const availableHeight = computed(() => {
      if (props.containerHeight) {
        const headerHeight = headerRef.value.clientHeight
        const footerHeight = footerRef.value.clientHeight
        const titleHeight = titleRef.value.clientHeight
        return headerHeight + footerHeight + titleHeight
      }
      return 0
    })

    function onSort(field, index) {
      sortField.value = field
      if (sortFieldIndex.value === index) {
        sortAsc.value = !sortAsc.value
      } else {
        sortFieldIndex.value = index
        sortAsc.value = true
      }
    }

    function getSelectLabel(field, value) {
      if (field.options) {
        const option = field.options.find((opt) => opt.value === value)
        return option ? option.label : value
      }
      return value
    }

    return {
      filteredRecords,
      filteredRecordsPaginated,
      pageIndex,
      movePpage,
      onFirstPage,
      onLastPage,
      lastPage,
      searchQuery,
      onAdd,
      onEdit,
      onDelete,
      showActions,
      showNoRecords,
      filteredFields,
      confirm,
      handleClick,
      rowStyle,
      parseKeypath,
      selected,
      isSelected,
      headerRef,
      footerRef,
      titleRef,
      availableHeight,
      useFilter,
      sortFieldIndex,
      onSort,
      sortedRecords,
      getSelectLabel,
      handleMiddleClick,
    }
  },
  components: {
    confimationModal,
    TrashIcon,
    PencilIcon,
    featherIcon,
    pillMainType,
    chevronupdown,
  },
}
</script>
