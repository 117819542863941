<template>
  <div>
    <nav
      :class="surfaceColour"
      class="flex flex-wrap items-center justify-between w-full py-4 md:py-0 px-4 h-16 font-montserrat text-gray-500"
    >
      <div><MenuIcon class="w-6 h-6" /></div>

      <svg
        xmlns="http://www.w3.org/2000/svg"
        id="menu-button"
        class="h-6 w-6 cursor-pointer md:hidden block"
        fill="none"
        viewBox="0 0 24 24"
        stroke="currentColor"
        @click="toggleMenu()"
      >
        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M4 6h16M4 12h16M4 18h16" />
      </svg>

      <div class="w-full md:flex md:items-center md:w-auto" id="menu">
        <ul class="pt-4 text-base md:flex md:justify-between md:pt-0">
          <li v-for="(item, index) in navBarConfig.items" :key="index">
            <a @click="action(item.action)" class="md:p-4 py-2 block" href="#">{{ item.title }}</a>
          </li>
        </ul>
      </div>
    </nav>
  </div>
</template>

<script>
import { ref, computed } from 'vue'
import { useStore } from 'vuex'
import { useAuth0 } from '@auth0/auth0-vue'
import useTheme from '@/use/themeHandler'
import { MenuIcon } from '@heroicons/vue/outline'

export default {
  setup() {
    const { logout } = useAuth0()
    const store = useStore()
    const showMenu = ref(false)
    const navBarConfig = computed(() => store.state.general.navBar)

    // colours
    const themeHandler = useTheme()
    const surfaceColour = themeHandler.getBackgroundColor('surface')

    function toggleMenu() {
      if (showMenu.value === false) {
        showMenu.value = true
      } else {
        showMenu.value = false
      }
    }

    function action(action) {
      if (action.type === 'storeMutation') {
        store.commit(action.target)
      } else if (action.type === 'logOut') {
        logout({ returnTo: window.location.origin })
      }
    }

    return {
      showMenu,
      toggleMenu,
      navBarConfig,
      action,
      surfaceColour,
    }
  },
  components: {
    MenuIcon,
  },
}
</script>
