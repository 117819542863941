<template>
  <div class="rounded shadow-lg bg-white h-full">
    <img v-if="image" class="w-full h-full" :src="image" :alt="imageAlt" />
    <div class="px-6 py-4">
      <div class="text-xl text-slate-500 mb-2">{{ title }}</div>
      <div class="flex">
        <baseLoader :loading="loading" class="mx-auto my-auto" />
      </div>
      <slot></slot>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      default: '',
    },
    image: {
      type: String,
      default: '',
    },
    imageAlt: {
      type: String,
      default: '',
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },
  setup() {},
}
</script>
