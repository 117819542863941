import { createRouter, createWebHistory } from 'vue-router'
import nodesView from '@/views/nodesView.vue'
import nodeDetailsView from '@/views/nodeDetailsView.vue'
import connectorView from '@/views/connectorView.vue'
import organisationsView from '@/views/organisationsView.vue'
import organisationDetailsView from '@/views/organisationDetailsView.vue'
import usersView from '@/views/usersView.vue'

import { authGuard } from '@auth0/auth0-vue'

const routes = [
  {
    path: '/',
    name: 'nodes',
    component: nodesView,
    beforeEnter: authGuard,
    meta: {
      layout: 'horizontal_sidebar',
      title: 'Nodes',
      titleIcon: 'CircleIcon',
    },
  },
  {
    path: '/connectors',
    name: 'connectors',
    component: connectorView,
    beforeEnter: authGuard,
    meta: {
      layout: 'horizontal_sidebar',
      title: 'Nodes',
      titleIcon: 'CircleIcon',
    },
  },
  {
    path: '/nodes/:objectId',
    name: 'node-view',
    component: nodeDetailsView,
    beforeEnter: authGuard,
    meta: {
      layout: 'horizontal_sidebar',
      title: 'Node',
      titleIcon: 'CircleIcon',
    },
  },
  {
    path: '/organisations',
    name: 'organisations',
    component: organisationsView,
    beforeEnter: authGuard,
    meta: {
      layout: 'horizontal_sidebar',
      title: 'Organisations',
      titleIcon: 'UsersIcon',
    },
  },
  {
    path: '/organisations/:objectId',
    name: 'organisation-view',
    component: organisationDetailsView,
    beforeEnter: authGuard,
    meta: {
      layout: 'horizontal_sidebar',
      title: 'Organisation',
      titleIcon: 'UsersIcon',
    },
  },
  {
    path: '/users',
    name: 'users',
    component: usersView,
    beforeEnter: authGuard,
    meta: {
      layout: 'horizontal_sidebar',
      title: 'users',
      titleIcon: 'UserIcon',
    },
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
})

export default router
