<template>
  <div class="z-20" aria-labelledby="modal-title" role="dialog" aria-modal="true">
    <!-- grey background -->
    <div class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity z-20"></div>

    <!-- card -->
    <div
      class="fixed z-20 inset-0 overflow-y-auto"
      @keyup.enter="enter()"
      @keydown.esc="close()"
      tabindex="0"
      ref="focusRef"
    >
      <div class="flex items-end items-center justify-center min-h-full p-4 text-center sm:p-0">
        <div
          :class="`relative bg-white rounded-lg text-left shadow-xl transform transition-all ${computedSize} w-full h-3/4 m-4`"
        >
          <div class="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
            <span class="flex justify-between">
              <div class="text-lg text-gray-500 mb-4">
                {{ title }}
              </div>
              <div class="text-gray-400 cursor-pointer" @click="close()">
                <!-- closer -->
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  class="h-6 w-6"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                  stroke-width="2"
                >
                  <path stroke-linecap="round" stroke-linejoin="round" d="M6 18L18 6M6 6l12 12" />
                </svg>
              </div>
            </span>

            <slot></slot>
          </div>
          <div class="bg-gray-50 px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse">
            <slot name="buttons"></slot>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ref, computed, onMounted } from 'vue'

export default {
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    title: {
      type: String,
      default: '',
    },
    size: {
      type: String,
      default: 'm',
    },
  },
  emits: ['close', 'enter'],
  setup(props, { emit }) {
    const sizeSelector = {
      xs: 'xl:w-1/5',
      s: 'xl:w-1/3',
      m: 'xl:w-1/2',
      l: 'xl:w-3/4',
      xl: 'xl:w-4/5',
    }

    const focusRef = ref(null)

    const computedSize = computed(() => {
      return sizeSelector[props.size]
    })

    function close() {
      emit('close')
    }

    function enter() {
      emit('enter')
    }

    onMounted(() => {
      focusRef.value.focus()
    })

    return {
      sizeSelector,
      close,
      computedSize,
      enter,
      focusRef,
    }
  },
}
</script>
