<template>
  <div class="h-full min-h-0 overflow-auto p-4">
    <base-page-title :title="pageTitle" :suffix="pageSuffix" :icon="meta.titleIcon"></base-page-title>

    <baseTable
      :fields="useOrganisations.fields.value"
      :records="useOrganisations.records.value"
      :records-per-page="15"
      :add-button="true"
      :edit-button="false"
      :delete-button="true"
      :clickAble="true"
      :clickCallback="goToDetails"
      :loading="useOrganisations.loadHandler.somethingLoading.value"
      :selectable="false"
      :hover-effect="true"
      keyField="object_id"
      @add="useOrganisations.openAdd"
      @delete="useOrganisations.deleteRecord"
      leadIcon="UsersIcon"
    />

    <!-- add modal -->
    <baseModal
      v-if="useOrganisations.showAdd.value"
      @close="useOrganisations.closeAdd"
      :title="$translate('Add')"
      size="s"
    >
      <baseForm :handler="useOrganisations.addForm" :loading="useOrganisations.loadHandler.states['add_record']" />
      <!-- add button -->
      <template v-slot:buttons>
        <baseButton @action="useOrganisations.addRecord()" :loading="useOrganisations.loadHandler.states[`add_record`]">
          {{ $translate('Add') }}
        </baseButton>
      </template>
    </baseModal>
  </div>
</template>

<script>
import { useRoute } from 'vue-router'
import { computed, onMounted } from 'vue'
import organisationsHandler from '@/use/viewHandlerOrganisations'
import { useRouter } from 'vue-router'

export default {
  setup() {
    const route = useRoute()
    const useOrganisations = organisationsHandler()
    const objectId = route.params.objectId
    const meta = computed(() => route.meta)
    const router = useRouter()

    const pageTitle = computed(() => {
      return meta.value.title
    })

    // const pageSuffix = computed(() => {
    //   if (!nodeDetails.loadHandler.states.get_details) {
    //     return `${nodeDetails.data.value.provider_identifier}`
    //   }
    //   return ''
    // })

    function goToDetails(record) {
      const objectId = record.object_id
      router.push({ name: 'organisation-view', params: { objectId: objectId } })
    }

    const pageSuffix = ''

    onMounted(() => {
      useOrganisations.getRecords()
    })

    return {
      useOrganisations,
      objectId,
      pageTitle,
      pageSuffix,
      meta,
      goToDetails,
    }
  },
}
</script>
