<template>
  <div class="h-full min-h-0 overflow-auto p-4">
    <div class="h-full">
      <baseTable
        :fields="useConnector.fields.value"
        :records="useConnector.records.value"
        :records-per-page="15"
        :add-button="true"
        :edit-button="true"
        :delete-button="true"
        :clickAble="true"
        :loading="useConnector.loadHandler.somethingLoading.value"
        :selectable="true"
        keyField="object_id"
        @add="useConnector.openAdd"
        @delete="useConnector.deleteRecord"
        @edit="onEdit"
      />
    </div>

    <!-- add modal -->
    <baseModal v-if="useConnector.showAdd.value" @close="useConnector.closeAdd" :title="$translate('Add')" size="s">
      <baseForm :handler="useConnector.addForm" :loading="useConnector.loadHandler.states['add_record']" />
      <!-- add button -->
      <template v-slot:buttons>
        <baseButton @action="useConnector.addRecord()" :loading="useConnector.loadHandler.states[`add_record`]">
          {{ $translate('Add') }}
        </baseButton>
      </template>
    </baseModal>

    <!-- edit modal -->
    <baseModal v-if="useConnector.showEdit.value" @close="useConnector.closeEdit()" :title="$translate('Edit')">
      <baseForm :handler="useConnector.editForm" :loading="useConnector.loadHandler.states['edit_record']" />
      <template v-slot:buttons>
        <baseButton @action="useConnector.editRecord()" :loading="useConnector.loadHandler.states[`edit_record`]">
          {{ $translate('Edit') }}
        </baseButton>
      </template>
    </baseModal>
  </div>
</template>

<script>
import { onMounted } from 'vue'
import connectorHandler from '@/use/viewHandlerConnector'

export default {
  setup() {
    const useConnector = connectorHandler()

    function onEdit(record) {
      useConnector.openEdit(record)
    }

    onMounted(() => {
      useConnector.getRecords()
    })

    return {
      useConnector,
      onEdit,
    }
  },
}
</script>
