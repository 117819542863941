import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import auth from './auth'
import i18n from './plugins/i18n/i18n'
import filters from './plugins/filters/filters'
import base from '@/plugins/base/index'

import './assets/tailwind.css'

const app = createApp(App)

app.use(i18n, {})
app.use(filters)
app.use(base)

app.use(store).use(router).use(auth).mount('#app')
