/* eslint-disable no-unused-vars */
export default () => {
  function validate(value, type, rules, payload) {
    // filter type
    const typeFilter = {
      stringStrict: isStringStrict(value),
      string: isString(value),
      number: isNumber(value),
      percentage: isPercentage(value),
      select: true,
      check: handleChecked(value),
      password: isPassword(value),
      email: isEmailKeystroke(value), // Changed to use the new function
    }

    if (!typeFilter[type]) {
      return false
    }

    return true
  }

  function isStringStrict(value) {
    const pattern = /[^a-zA-Z ]/
    return !pattern.test(value)
  }

  function isString(value) {
    const pattern = /^[a-zA-Z0-9\-()\s]*$/
    return pattern.test(value)
  }

  function isNumber(value) {
    const pattern = /^(?:(?:0|[1-9][0-9]*)(?:\.[0-9]*)?|\.[0-9]+)$|^$/
    return pattern.test(value)
  }

  function isPercentage(value) {
    const pattern = /^([0-9]|[1-9][0-9]|100)$|^$/
    return pattern.test(value)
  }

  function handleChecked(value) {
    return value
  }

  function isPassword(value) {
    const pattern = /^\S*$/
    return pattern.test(value)
  }

  function isEmail(value) {
    const pattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/
    return pattern.test(value)
  }

  function isEmailKeystroke(value) {
    if (value === '') return true // Allow empty input

    // This regex allows partial email input
    const pattern = /^[^\s@]*@?[^\s@]*\.?[^\s@]*$/
    return pattern.test(value)
  }

  function validatePassword(password) {
    const errors = []

    if (password.length < 8) {
      errors.push('Wachtwoord moet minimaal 8 tekens lang zijn')
    }
    if (!/[a-z]/.test(password)) {
      errors.push('Wachtwoord moet minimaal één kleine letter bevatten')
    }
    if (!/[A-Z]/.test(password)) {
      errors.push('Wachtwoord moet minimaal één hoofdletter bevatten')
    }
    if (!/[0-9]/.test(password)) {
      errors.push('Wachtwoord moet minimaal één cijfer bevatten')
    }
    if (!/[!@#$%^&*(),.?":{}|<>]/.test(password)) {
      errors.push('Wachtwoord moet minimaal één speciaal teken bevatten')
    }
    if (/(.)\1{2,}/.test(password)) {
      errors.push('Wachtwoord mag niet meer dan twee identieke tekens op een rij bevatten')
    }

    return errors
  }

  return {
    validate,
    validatePassword,
  }
}
