import { ref } from 'vue'
import apiHandler from '@/use/apiHandler'
import loadingHandler from '@/use/loadingHandler'
import formHandler from '@/use/formHandler'
// import selectValues from '@/use/selects/values'
import useToast from '@/use/toastHandler'

export default () => {
  // data
  let records = ref([])
  let showAdd = ref(false)
  let showEdit = ref(false)

  // handlers
  const useApi = apiHandler()
  const loadHandler = loadingHandler()
  const addForm = formHandler()
  const editForm = formHandler()
  const toasthandler = useToast()

  const fields = ref([
    {
      label: 'Object ID',
      key: 'object_id',
      type: 'id',
      table: false,
      add: false,
      edit: false,
    },
    {
      label: 'Name',
      key: 'name',
      type: 'string',
      searchable: true,
    },
  ])

  // list
  function getRecords() {
    loadHandler.setLoadingState('get_records', true)

    useApi.requestV2('get', 'v1/organisations/').then((response) => {
      records.value = response.data
      loadHandler.setLoadingState('get_records', false)
    })
  }

  // add
  function addRecord() {
    const payload = addForm.data.value
    loadHandler.setLoadingState('add_record', true)

    useApi
      .requestV2('post', 'v1/organisations/', payload)
      .then(() => {
        loadHandler.setLoadingState('add_record', false)
        showAdd.value = false
        getRecords()
      })
      .catch((error) => {
        const errorCode = error.request.status
        if (errorCode === 409) {
          toasthandler.addToast('Organisatienaam bestaat al', 'danger')
        }
        loadHandler.setLoadingState('add_record', false)
      })
  }

  // open edit
  function openEdit(data) {
    editForm.create({ type: 'edit', fields: fields.value, data: data })
    showEdit.value = true
  }

  function openAdd() {
    addForm.create({ type: 'add', fields: fields.value, data: {} })
    showAdd.value = true
  }

  function closeAdd() {
    addForm.data.value = {}
    showAdd.value = false
  }

  function closeEdit() {
    editForm.data.value = {}
    showEdit.value = false
  }

  // edit
  function editRecord() {
    const payload = editForm.data.value
    const object_id = payload.object_id

    loadHandler.setLoadingState('edit_record', true)

    useApi.requestV2('patch', `v1/connectors/${object_id}`, payload).then(() => {
      loadHandler.setLoadingState('edit_record', false)
      closeEdit()
      getRecords()
    })
  }

  // delete
  function deleteRecord(value) {
    loadHandler.setLoadingState('delete_record', true)
    useApi.requestV2('delete', `v1/organisations/${value}`).then(() => {
      loadHandler.setLoadingState('delete_record', false)
      getRecords()
    })
  }

  return {
    records,
    getRecords,
    addRecord,
    fields,
    addForm,
    editForm,
    showAdd,
    showEdit,
    openEdit,
    openAdd,
    editRecord,
    deleteRecord,
    loadHandler,
    closeAdd,
    closeEdit,
  }
}
