import { readonly, ref, computed } from 'vue'
import apiHandler from '@/use/apiHandler'
import loadingHandler from '@/use/loadingHandler'
// import formHandler from '@/use/formHandler'

export default () => {
  // data
  const provider_records = ref([])
  const provider_records_selection = ref({})
  const database_records = ref([])
  const showAdd = ref(false)
  const showEdit = ref(false)

  // handlers
  const useApi = apiHandler()
  const loadHandler = loadingHandler()

  const fields = ref([
    {
      label: 'Provider',
      key: 'provider',
      type: 'string',
      searchable: true,
    },
    {
      label: 'Identifier',
      key: 'provider_identifier',
      type: 'string',
      searchable: true,
    },
    {
      label: 'Medium',
      key: 'medium',
      type: 'string',
      searchable: true,
      component: 'pillMedium',
    },
    {
      label: 'Address',
      key: 'address',
      type: 'string',
      searchable: true,
    },
    {
      label: 'Place',
      key: 'place',
      type: 'string',
      searchable: true,
    },
  ])

  const fieldsProvider = ref([
    {
      label: 'Provider',
      key: 'provider',
      type: 'string',
      searchable: true,
    },
    {
      label: 'Identifier',
      key: 'provider_identifier',
      type: 'string',
      searchable: true,
    },
    {
      label: 'Medium',
      key: 'medium',
      type: 'string',
      searchable: true,
      component: 'pillMedium',
    },
    {
      label: 'Provider Address',
      key: 'provider_address',
      type: 'string',
      searchable: true,
    },
    {
      label: 'Provider Place',
      key: 'provider_place',
      type: 'string',
      searchable: true,
    },
  ])

  function findUniqueElements(a, b) {
    return a.filter((aElement) => {
      // Get the value of the "provider identifier" property of the current object in array a
      const aProviderId = aElement['provider_identifier']
      // Check if there is an object in array b with the same "provider identifier" value
      const hasDuplicate = b.some((bElement) => bElement['provider_identifier'] === aProviderId)
      // Return true if the current object in array a has a unique "provider identifier" value
      return !hasDuplicate
    })
  }

  const filteredAvailableNodes = computed(() => {
    if (provider_records.value) {
      return findUniqueElements(provider_records.value, database_records.value)
    }
    return []
  })

  // list available nodes
  function getNodesAvailable() {
    loadHandler.setLoadingState('list_nodes_available', true)
    useApi
      .requestV2(
        'get',
        'v1/nodes_available/?fields=provider,provider_identifier,provider_address,provider_place,object_id,medium'
      )
      .then((response) => {
        provider_records.value = response.data
        loadHandler.setLoadingState('list_nodes_available', false)
      })
  }

  // list database nodes
  function getNodes() {
    loadHandler.setLoadingState('list_nodes', true)
    useApi
      .requestV2('get', 'v1/nodes/?fields=provider,provider_identifier,address,place,object_id,medium')
      .then((response) => {
        database_records.value = response.data
        loadHandler.setLoadingState('list_nodes', false)
      })
  }

  // add
  function addNodes() {
    loadHandler.setLoadingState('add_nodes', true)

    // parse the selected provider records
    const object_ids = []
    Object.keys(provider_records_selection.value).forEach((key) => {
      object_ids.push(provider_records_selection.value[key]['object_id'])
    })

    // request the available nodes with full details
    const nodeIdsString = object_ids.map((id) => 'ids=' + id).join('&')
    useApi.requestV2('get', `v1/nodes_available/?${nodeIdsString}`).then((response) => {
      useApi.requestV2('post', `v1/nodes/`, response.data).then(() => {
        loadHandler.setLoadingState('add_nodes', false)
        closeAdd()
        getNodes()
      })
    })
  }

  function deleteNodes(value) {
    loadHandler.setLoadingState('delete_nodes', true)

    useApi.requestV2('delete', `v1/nodes/${value}`).then(() => {
      loadHandler.setLoadingState('delete_nodes', false)
      getNodes()
    })
  }

  function openAdd() {
    getNodesAvailable()
    showAdd.value = true
  }

  function closeAdd() {
    provider_records.value = []
    provider_records_selection.value = []
    showAdd.value = false
  }

  function syncSelection(selection) {
    provider_records_selection.value = selection.value
  }

  // exposed
  return {
    fields: readonly(fields),
    provider_records,
    database_records,
    loadHandler,
    showAdd,
    showEdit,
    openAdd,
    closeAdd,
    syncSelection,
    provider_records_selection,
    deleteNodes,
    getNodes,
    getNodesAvailable,
    addNodes,
    filteredAvailableNodes,
    fieldsProvider,
  }
}
