<template>
  <div class="font-montserrat">
    <component :is="layout">
      <router-view />
    </component>

    <!-- toasts -->
    <base-toast></base-toast>
  </div>
</template>

<script>
import navigationBar from '@/components/navigationBar.vue'
import LayoutFull from '@/layouts/LayoutFull.vue'
import LayoutHorizontal from '@/layouts/LayoutHorizontal.vue'
import LayoutHorizontalSideBar from '@/layouts/LayoutHorizontalSideBar.vue'
import { computed } from 'vue'
import { useRoute } from 'vue-router'

export default {
  setup() {
    const route = useRoute()

    const layoutSelector = {
      full: 'layout-full',
      horizontal: 'layout-horizontal',
      horizontal_sidebar: 'layout-horizontal-side-bar',
    }

    const layout = computed(() => {
      return layoutSelector[route.meta.layout]
    })

    return {
      layout,
    }
  },
  components: {
    LayoutHorizontal,
    LayoutHorizontalSideBar,
    LayoutFull,
    navigationBar,
  },
}
</script>
