import { useAuth0 } from '@auth0/auth0-vue'
import axios from 'axios'

export default () => {
  const { getAccessTokenSilently } = useAuth0()

  // request interceptor
  axios.interceptors.request.use(async (config) => {
    // use auth0 function to get token
    const accessToken = await getAccessTokenSilently()

    // add to header
    if (accessToken) {
      config.headers.common = { Authorization: `Bearer ${accessToken}` }
    }
    return config
  })

  // response interceptor
  axios.interceptors.response.use(
    (response) => {
      return response
    },
    (error) => {
      return Promise.reject(error)
    }
  )

  const baseUrlV2 = process.env.VUE_APP_API_V2_BASEURL

  async function requestV2(type, endpoint, payload) {
    if (type === 'get') {
      const response = await axios.get(`${baseUrlV2}${endpoint}`)
      return response
    } else if (type === 'post') {
      if (!payload) {
        console.error(`A Post request to ${endpoint} needs a payload!`)
        return null
      }
      const response = await axios.post(`${baseUrlV2}${endpoint}`, payload)
      return response
    } else if (type === 'delete') {
      const response = await axios.delete(`${baseUrlV2}${endpoint}`)
      return response
    } else if (type === 'put') {
      const response = await axios.put(`${baseUrlV2}${endpoint}`, payload)
      return response
    } else if (type === 'patch') {
      const response = await axios.patch(`${baseUrlV2}${endpoint}`, payload)
      return response
    } else if (type === 'download') {
      const response = await axios.get(`${baseUrlV2}${endpoint}`, { responseType: 'blob' })
      return response
    }
  }

  function toQueryString(params) {
    const queryString = new URLSearchParams()
    Object.keys(params).forEach((key) => {
      const value = params[key]
      if (Array.isArray(value)) {
        value.forEach((val) => queryString.append(key, val))
      } else {
        queryString.append(key, value)
      }
    })
    return queryString.toString()
  }

  function arrayToCommaSeparatedString(array) {
    if (!Array.isArray(array)) {
      throw new Error('Input must be an array')
    }
    return array.join(',')
  }

  return {
    toQueryString,
    requestV2,
    arrayToCommaSeparatedString,
  }
}
