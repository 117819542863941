<template>
  <!-- split sidebar and rest -->
  <div class="flex flex-col w-full h-full p-4">
    <!-- close button -->
    <div class="w-full">
      <logoSideBar class="w-full h-full" />
    </div>

    <!-- content -->
    <div class="mt-8">
      <ul>
        <li v-for="item in items" :key="item">
          <div
            v-if="!item.header"
            @click="goTo(item.route_name)"
            class="flex flex-row font-montserrat text-gray-500 p-2 hover:translate-x-1 ease-in-out duration-300 cursor-pointer"
          >
            <div class="mr-4 my-auto"><featherIcon class="w-5 h-5" :icon="item.icon" /></div>
            <div class="align-middle">{{ $translate(item.label) }}</div>
          </div>
          <div v-else class="flex flex-row text-xs font-montserrat text-gray-500 p-2 mt-4 uppercase">
            {{ $translate(item.header) }}
          </div>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import useTheme from '@/use/themeHandler'
import logoSideBar from '@/components/svg/logoSideBar.vue'
import featherIcon from '@/components/extended/feather/featherIcon.vue'
import { useRouter } from 'vue-router'

export default {
  setup() {
    const themeHandler = useTheme()
    const surfaceColour = themeHandler.getBackgroundColor('surface')
    const backgroundColor = themeHandler.getBackgroundColor('background')

    const router = useRouter()

    const items = [
      { header: 'Data' },
      { label: 'Connectors', icon: 'Link2Icon', route: '/connectors', route_name: 'connectors' },
      { label: 'Nodes', icon: 'CircleIcon', route: '/', route_name: 'nodes' },
      { header: 'Applicatie' },
      { label: 'Organisations', icon: 'UsersIcon', route: '/organisations', route_name: 'organisations' },
      { label: 'Users', icon: 'UserIcon', route: '/users', route_name: 'users' },
    ]

    function goTo(route_name) {
      router.push({ name: route_name })
    }

    return { backgroundColor, surfaceColour, items, goTo }
  },
  components: {
    logoSideBar,
    featherIcon,
  },
}
</script>
